import React from 'react';
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"
import { TEMP_IMAGES } from "../model/constats"

const VOkoli = props => {
	if (typeof window !== 'undefined') {
		navigate('/');
		return null;
	}

	return (
		<Layout>
			<SEO title="V okolí" />
			<div className="container mx-auto p-8 mb-3">
				<h1 className="text-5xl text-gray-900">
					V okolí
				</h1>

				<div className="mt-5">
					<p className="text-gray-600">
						<ul className="list-disc">
							<li>
								ZOO Lešná u Zlína
							</li>
							<li>
								zámek Bojkovice
							</li>
						</ul>
						{/*Za návštěvu stojí:   ZOO Lešná u Zlína,  zámek Bojkovice,  Aquapark Uherský Brod,*/}
						{/*Slavičín- zámek a lesopark, Vizovice – zámek a lihovar R. Jelínek,  Velehrad – poutní místo*/}
						{/*Baťův kanál – turistická vodní cesta, možnost plavby po kanálu*/}
						{/*Archeoskanzen Modrá,    Kroměříž – zámek a zahrady jsou na seznamu UNESCO,     Buchlovice – zámek a zahrady*/}
					</p>

					<div className="mt-5">
						<Gallery images={ TEMP_IMAGES }/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default VOkoli;
